import React from "react"
import { useQuery } from "react-query"
import { useNfts } from "@contexts/nft"
import { useUser } from "@contexts/user"
import { toast } from "react-toastify"
import { useLocalization, LocalizedLink as Link } from "gatsby-theme-i18n"
import { useTranslation } from "react-i18next"
import { useFeatures } from "@contexts/features"
import { Layout } from "@bw/layouts"
import { Share, Creator, Historic } from "@bw/partials"
import { getNftByAddress, buyNft } from "@actions/nft"
import { likeNft, unlikeNft } from "@actions/nft"
import { getProfileByAddress, getBalance } from "@actions/user"
import { useWallet } from "@solana/wallet-adapter-react"
import { Like } from "@bw/icons"
import {
  Title,
  Section,
  Avatar,
  Button,
  KeyValue,
  Image,
  Spacer,
  Loader,
} from "@bw/bits"
import { Box } from "grommet"
import styled from "styled-components"
import { navigate } from "gatsby"
import { breakpoints } from "../../theme"
import { LAMPORT_MULTIPLIER } from "../../utils/oyster/common/src/index.tsx"

const NftPage = ({ params, location, pageContext }) => {
  const [features] = useFeatures()
  const [nfts, dispatchNfts] = useNfts()
  const [user, dispatch] = useUser()
  const wallet = useWallet()
  const { locale, localizedPath, defaultLang } = useLocalization()
  const { originalPath } = pageContext
  const { t } = useTranslation()
  const [likes, setLikes] = React.useState(null)
  const nftQuery = useQuery(`nft_${params.address}`, async () => {
    const nft = await getNftByAddress(params.address)
    nft.creators = []

    for (let i = 0; i < nft.creators_address.length; i++) {
      try {
        let creator = await getProfileByAddress(nft.creators_address[i])
        nft.creators.push({
          name: creator?.name || "Unknown",
          address: creator?.address,
          avatar_url: creator?.avatar_url,
          url: `/user/${creator.address}`,
        })
      } catch {
        nft.creators.push({
          address: nft.creators_address[i],
        })
      }
    }

    return nft
  })

  const userLikeNft = user.data.likes_nfts.includes(params.address)
  React.useEffect(() => {
    if (typeof nftQuery?.data?.likes_count !== "undefined")
      setLikes(nftQuery.data.likes_count)
  }, [nftQuery.data])

  const listedInfo = nfts.listed.find(nft => nft.nft === params.address)

  if (typeof params.address === "undefined") {
    return null
  }

  if (nftQuery.isError) {
    navigate(
      localizedPath({
        defaultLang,
        locale,
        path: `/collections/`,
      })
    )
    toast.error(t("nft.notFound"))
    return null
  }
  // defines if the nft is listed and is mine
  const isMine = nfts.listed.find(
    nft => nft.nft === params.address && nft.seller === user.publicKey
  )

  const isInFallbackCollection =
    nftQuery?.data?.collection?.address ===
    process.env.GATSBY_FALLBACK_COLLECTION_ADDRESS

  return (
    <Layout
      pageContext={{
        ...pageContext,
        originalPath: originalPath.replace("[address]", params.address),
      }}
    >
      {location.state && location.state.from && (
        <Section>
          <Button
            direction="row-reverse"
            icon="ArrowLeft"
            to={location.state.from}
            label={t("return")}
            small
          />
        </Section>
      )}
      <Section>
        {nftQuery.isLoading && (
          <Loader
            messages={[
              t("loading.theNft"),
              t("loading.itTakesLongerThanExpected"),
            ]}
            treshold={5}
          />
        )}
        {nftQuery.isSuccess && (
          <MainGrid>
            <div>
              <Image
                type="NFTDetail"
                image={`${process.env.GATSBY_API_URL}/images/${nftQuery.data.image}`}
                avatar={
                  nftQuery.data?.collection?.icon_url ? (
                    <Avatar
                      size="small"
                      image={nftQuery.data.collection.icon_url}
                    />
                  ) : null
                }
              />
            </div>
            <Box direction="column">
              <Spacer>
                <Title
                  title={nftQuery.data.name}
                  subtitle={nftQuery.data?.collection?.name}
                />
                <Creators>
                  {nftQuery.data.creators.map(creator => {
                    const teaser = (
                      <Creator
                        avatar={
                          <Avatar size="small" image={creator.avatar_url} />
                        }
                        creator={creator.name || t("unknown")}
                        username={
                          creator.address.slice(0, 4) +
                          "..." +
                          creator.address.slice(-4)
                        }
                      />
                    )

                    if (typeof creator.name !== "undefined") {
                      return (
                        <Link
                          to={`/user/${creator.address}/`}
                          key={creator.address}
                        >
                          {teaser}
                        </Link>
                      )
                    }

                    return (
                      <a
                        key={creator.address}
                        href={`https://explorer.solana.com/address/${creator.address}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {teaser}
                      </a>
                    )
                  })}
                </Creators>
                <IconeWrap
                  role="button"
                  onClick={() => {
                    if (likes === null) {
                      return false
                    }
                    if (userLikeNft) {
                      unlikeNft(params.address)
                        .then(data => {
                          if (!data.unliked) {
                            throw new Error()
                          }
                          toast.success(t("user.toastUnlikeNftSuccess"))
                          dispatch({
                            type: "UNLIKE_NFT",
                            address: params.address,
                          })
                          setLikes(likes - 1)
                        })
                        .catch(function (error) {
                          toast.error(t("user.toastUnlikeNftFail"))
                        })
                    } else {
                      likeNft(params.address)
                        .then(data => {
                          if (!data.liked) {
                            throw new Error()
                          }
                          toast.success(t("user.toastLikeNftSuccess"))
                          dispatch({
                            type: "LIKE_NFT",
                            address: params.address,
                          })
                          setLikes(likes + 1)
                        })
                        .catch(function (error) {
                          toast.error(t("user.toastLikeNftFail"))
                        })
                    }
                  }}
                >
                  <Like
                    strokeColor="#6080E9"
                    fillColor={userLikeNft ? "#6080E9" : ""}
                  />
                  <small>{likes}</small>
                </IconeWrap>
                <Share
                  fillColor="#6080E9"
                  row={true}
                  pageURL={location.href}
                  quote={nftQuery.data.name}
                />
              </Spacer>
              <div style={{ marginTop: "auto", paddingTop: "25px" }}>
                <Spacer gap="small" direction="row">
                  <Button
                    label={t("nft.viewOnSolana")}
                    target="_blank"
                    href={`https://explorer.solana.com/address/${params.address}`}
                  />
                  {features.includes("allowListUnlistBuyNFT") &&
                    !isMine &&
                    listedInfo &&
                    user.loggedIn && (
                      <Button
                        label={t("nft.buyForXSol", {
                          price: listedInfo.price / LAMPORT_MULTIPLIER,
                        })}
                        onClick={() => {
                          getBalance(user.publicKey)
                            .then(lamports => {
                              if (lamports < listedInfo.price + 5000) {
                                throw new Error("Unsuficient lamports")
                              }

                              return buyNft(
                                wallet,
                                listedInfo.seller,
                                listedInfo.nft,
                                listedInfo.dataAccount,
                                listedInfo.price
                              )
                            })
                            .then(() => {
                              toast.success(t("nft.toastNftSuccessfullyBuy"))
                              dispatchNfts({
                                type: "UNLIST",
                                address: listedInfo.nft,
                              })
                            })
                            .catch(err => {
                              if (err.message === "Unsuficient lamports") {
                                toast.error(t("nft.toastUnsufficientFunds"))
                              }
                            })
                          return
                        }}
                      />
                    )}
                </Spacer>
              </div>
            </Box>
            <div>
              <h3>{t("nft.description")}</h3>
              <p>{nftQuery.data?.description}</p>

              {nftQuery.data.attributes.length > 0 && (
                <>
                  <h3>{t("nft.attributes")}</h3>
                  <Attributes>
                    {nftQuery.data.attributes.map(attribute => {
                      const attributeKeyValue = (
                        <KeyValue
                          direction="column"
                          indicator={attribute.trait_type}
                          variable={attribute.value}
                          size="large"
                        />
                      )
                      // NFTs in the fallback collection cant be filtered
                      // by attribute
                      if (isInFallbackCollection) {
                        return attributeKeyValue
                      }

                      return (
                        <Link
                          key={attribute.trait_type}
                          to={localizedPath({
                            defaultLang,
                            locale,
                            path: `/collection/${nftQuery.data.symbol}/`,
                          })}
                          state={attribute}
                        >
                          {attributeKeyValue}
                        </Link>
                      )
                    })}
                  </Attributes>
                </>
              )}
            </div>
            <div>
              <h3>{t("nft.history")}</h3>
              {nftQuery.data.sales_history.length > 0 ? (
                <Spacer>
                  {nftQuery.data.sales_history.map((sale, i) => (
                    <Historic
                      key={i}
                      avatar={
                        <Avatar size="small" image={sale.buyer.avatar_url} />
                      }
                      price={sale.price}
                      buyer={"@" + sale.buyer.name}
                      date={new Date(sale.sold_at).toLocaleDateString(locale, {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        minute: "2-digit",
                        hour: "2-digit",
                      })}
                    />
                  ))}
                </Spacer>
              ) : (
                <p>{t("nft.noHistory")}</p>
              )}
            </div>
          </MainGrid>
        )}
      </Section>
    </Layout>
  )
}

const MainGrid = styled.div`
  display: grid;
  gap: 30px;

  @media (min-width: ${breakpoints.medium}px) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 75px;
  }

  @media (min-width: ${breakpoints.large}px) {
    grid-column-gap: 230px;
  }
`

const Attributes = styled.div`
  display: grid;
  gap: 15px;
  @media (min-width: ${breakpoints.medium}px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const Creators = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr 1fr;
`

const IconeWrap = styled.div`
  height: 24px;
  display: flex;
  color: #04062c;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  small {
    opacity: 0.8;
    font-size: 14px;
  }

  @media (max-width: ${breakpoints.medium}px) {
    gap: 25px;
  }
`

export default NftPage
